// Analytics event tracking functions
const trackEvent = (eventName, properties = {}) => {
    if (window.gtag) {
        gtag('event', eventName, properties);
    }
    if (window.plausible) {
        plausible(eventName, { props: properties });
    }
};

// Product view events
const trackProductView = (productName) => {
    trackEvent('product_view', {
        product_name: productName
    });
};

// Product page events
const trackProductPageView = (productName, price) => {
    trackEvent('product_page_view', {
        product_name: productName,
        price: price
    });
};

const trackProductImageView = (productName, imageUrl) => {
    trackEvent('product_image_view', {
        product_name: productName,
        image: imageUrl
    });
};

const trackSpecTableView = (productName) => {
    trackEvent('specification_table_view', {
        product_name: productName
    });
};

// Track specific specification clicks
const trackSpecificationClick = (productName, specName, specValue) => {
    trackEvent('specification_click', {
        product_name: productName,
        specification: specName,
        value: specValue
    });
};

// Track tab changes
const trackTabChange = (productName, tabName) => {
    trackEvent('tab_change', {
        product_name: productName,
        tab_name: tabName
    });
};

// Track time spent on tab
const trackTabTimeEngagement = (productName, tabName, timeSpentSeconds) => {
    trackEvent('tab_time_spent', {
        product_name: productName,
        tab_name: tabName,
        time_seconds: timeSpentSeconds
    });
};

// Track interactive guide interactions
const trackInteractiveGuideClick = (productName, componentName) => {
    trackEvent('interactive_guide_click', {
        product_name: productName,
        component_name: componentName
    });
};

// Track document downloads
const trackDocumentDownload = (productName, documentName) => {
    trackEvent('document_download', {
        product_name: productName,
        document_name: documentName
    });
};

// Track FAQ interactions
const trackFAQInteraction = (productName, questionText, action) => {
    trackEvent('faq_interaction', {
        product_name: productName,
        question: questionText,
        action: action // 'expand' or 'collapse'
    });
};

// Track time spent on specifications
const trackSpecTimeEngagement = (productName, timeSpentSeconds) => {
    trackEvent('specification_time_spent', {
        product_name: productName,
        time_seconds: timeSpentSeconds
    });
};

// Track price engagement
const trackPriceEngagement = (productName, price) => {
    trackEvent('price_view', {
        product_name: productName,
        price: price
    });
};

// Track educational discount interest
const trackEducationalInterest = (productName) => {
    trackEvent('educational_discount_interest', {
        product_name: productName
    });
};

// Contact form events
const trackContactFormSubmit = (formType, productContext) => {
    trackEvent('contact_form_submit', {
        form_type: formType,
        product_context: productContext
    });
};

// Track form field engagement
const trackFormFieldEngagement = (formType, fieldName) => {
    trackEvent('form_field_interaction', {
        form_type: formType,
        field: fieldName
    });
};

// Navigation events
const trackNavigation = (destination) => {
    trackEvent('navigation', {
        destination: destination
    });
};

// CTA clicks
const trackCTAClick = (ctaText, ctaLocation) => {
    trackEvent('cta_click', {
        cta_text: ctaText,
        cta_location: ctaLocation
    });
};

// Scroll depth tracking
const initScrollDepthTracking = () => {
    let tracked25 = false;
    let tracked50 = false;
    let tracked75 = false;
    let tracked100 = false;

    window.addEventListener('scroll', () => {
        const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
        
        if (!tracked25 && scrollPercent >= 25) {
            trackEvent('scroll_depth', { depth: '25%' });
            tracked25 = true;
        }
        if (!tracked50 && scrollPercent >= 50) {
            trackEvent('scroll_depth', { depth: '50%' });
            tracked50 = true;
        }
        if (!tracked75 && scrollPercent >= 75) {
            trackEvent('scroll_depth', { depth: '75%' });
            tracked75 = true;
        }
        if (!tracked100 && scrollPercent >= 90) {
            trackEvent('scroll_depth', { depth: '100%' });
            tracked100 = true;
        }
    });
};

// Product page specific tracking
const initProductPageTracking = () => {
    // Get product info
    const productName = document.querySelector('h1')?.textContent?.trim() || 'Unknown Product';
    const priceElement = document.querySelector('.product-price ins');
    const price = priceElement ? priceElement.textContent.trim() : 'Unknown Price';

    // Track page view
    trackProductPageView(productName, price);

    // Track tab changes and time spent
    let currentTab = null;
    let tabViewStartTime = null;

    document.querySelectorAll('.nav-tabs .nav-link').forEach(tab => {
        tab.addEventListener('click', () => {
            const tabName = tab.textContent?.trim();
            if (tabName) {
                // Track tab change
                trackTabChange(productName, tabName);
                
                // Track time spent on previous tab
                if (currentTab && tabViewStartTime) {
                    const timeSpent = Math.round((Date.now() - tabViewStartTime) / 1000);
                    trackTabTimeEngagement(productName, currentTab, timeSpent);
                }
                
                // Reset timer for new tab
                currentTab = tabName;
                tabViewStartTime = Date.now();
            }
        });
    });

    // Track interactive guide interactions
    document.querySelectorAll('.guide-point').forEach(point => {
        point.addEventListener('click', () => {
            const componentName = point.getAttribute('data-component');
            if (componentName) {
                trackInteractiveGuideClick(productName, componentName);
            }
        });
    });

    // Track document downloads
    document.querySelectorAll('#docs-faq a[href$=".pdf"]').forEach(link => {
        link.addEventListener('click', () => {
            const documentName = link.textContent?.trim() || link.getAttribute('href').split('/').pop();
            trackDocumentDownload(productName, documentName);
        });
    });

    // Track FAQ interactions
    document.querySelectorAll('#faqAccordion .accordion-button').forEach(button => {
        button.addEventListener('click', () => {
            const isExpanded = button.classList.contains('collapsed');
            const questionText = button.textContent?.trim();
            if (questionText) {
                trackFAQInteraction(productName, questionText, isExpanded ? 'expand' : 'collapse');
            }
        });
    });

    // Track image gallery interactions
    document.querySelectorAll('.gallery-main img').forEach(img => {
        img.addEventListener('click', () => {
            trackProductImageView(productName, img.getAttribute('src'));
        });
    });

    // Track specification table interactions
    const specTable = document.querySelector('.table-striped');
    if (specTable) {
        let specViewStartTime = null;

        // Track when specifications come into view
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    trackSpecTableView(productName);
                    specViewStartTime = Date.now();
                } else if (specViewStartTime) {
                    const timeSpent = Math.round((Date.now() - specViewStartTime) / 1000);
                    trackSpecTimeEngagement(productName, timeSpent);
                    specViewStartTime = null;
                }
            });
        }, { threshold: 0.5 });
        observer.observe(specTable);

        // Track clicks on specific specifications
        specTable.querySelectorAll('tr').forEach(row => {
            row.addEventListener('click', () => {
                const specName = row.querySelector('td:first-child')?.textContent?.trim();
                const specValue = row.querySelector('td:last-child')?.textContent?.trim();
                if (specName && specValue) {
                    trackSpecificationClick(productName, specName, specValue);
                }
            });
        });
    }

    // Track price engagement
    const priceSection = document.querySelector('.product-price');
    if (priceSection) {
        const priceObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    trackPriceEngagement(productName, price);
                }
            });
        }, { threshold: 0.8 });
        priceObserver.observe(priceSection);
    }

    // Track contact sales button clicks
    document.querySelector('.add-to-cart')?.addEventListener('click', () => {
        trackCTAClick('Contact Sales', productName);
    });

    // Track similar products clicks
    document.querySelectorAll('.similar-products a').forEach(link => {
        link.addEventListener('click', () => {
            trackNavigation(`Similar Product: ${link.getAttribute('data-product-name') || 'Unknown'}`);
        });
    });

    // Track form field engagement
    document.querySelectorAll('form').forEach(form => {
        const formType = form.getAttribute('data-form-type') || 'unknown';
        form.querySelectorAll('input, textarea').forEach(field => {
            field.addEventListener('focus', () => {
                trackFormFieldEngagement(formType, field.name);
            });
        });
    });

    // Track educational discount interest
    document.querySelectorAll('a[href*="educational"], button[data-bs-target*="educational"]').forEach(element => {
        element.addEventListener('click', () => {
            trackEducationalInterest(productName);
        });
    });
};

// Initialize all tracking
const initAnalytics = () => {
    initScrollDepthTracking();
    
    // Initialize product page tracking if we're on a product page
    if (document.querySelector('.single-product')) {
        initProductPageTracking();
    }
    
    // Track product card clicks
    document.querySelectorAll('.product-link').forEach(link => {
        link.addEventListener('click', (e) => {
            const productName = link.getAttribute('data-product-name');
            if (productName) {
                trackProductView(productName);
            }
        });
    });

    // Track CTA clicks
    document.querySelectorAll('[data-track-cta]').forEach(cta => {
        cta.addEventListener('click', (e) => {
            const ctaText = cta.innerText;
            const ctaLocation = cta.getAttribute('data-track-location');
            trackCTAClick(ctaText, ctaLocation);
        });
    });

    // Track form submissions with product context
    document.querySelectorAll('form').forEach(form => {
        form.addEventListener('submit', (e) => {
            const formType = form.getAttribute('data-form-type') || 'unknown';
            const productContext = document.querySelector('h1')?.textContent?.trim() || null;
            trackContactFormSubmit(formType, productContext);
        });
    });
};

export { initAnalytics };