import { initCounter } from './assets/js/counter.js';
import { initAnalytics } from './assets/js/analytics.js';
import { Core, Base, Modules, Mobile, Custom } from './assets/js/functions.js';

// Initialize global objects for backward compatibility
window.SEMICOLON = { Core, Base, Modules, Mobile, Custom };
window.CNVS = {}; // Initialize empty CNVS object to store dynamically loaded modules

window.initializeSwiper = () => {
  if (window.Swiper != null) {
    const swiperThumb = new window.Swiper('.swiper-thumb', {
      spaceBetween: 10,
      slidesPerView: 4,
      allowTouchMove: true,
      freeMode: true,
      watchSlidesProgress: true,
    });

    const swiperContainer = new window.Swiper('.swiper', {
      spaceBetween: 10,
      allowTouchMove: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: swiperThumb,
      },
    });
  } else {
    console.warn("Swiper doesn't exist!");
  }
};

window.addEventListener('load', function () {
  initCounter();

  queueMicrotask(() => {
    window.initializeSwiper();

    // Contact sales form
    document.getElementById('form-contact-sales').addEventListener('formSubmitSuccess', function() {
      document.getElementById('bs-modal-contact-sales').modal('toggle');
    });
    document.getElementById('form-contact-sales').addEventListener('formSubmitFailed', function() {
      SEMICOLON.Modules.notifications(document.querySelector('#form-contact-sales-error-message')); 
    });
  });
});

document.addEventListener('DOMContentLoaded', function() {
  initAnalytics();
});
